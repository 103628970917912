import React from "react";
import Section from "../../components/Section/Section";

import "./Hero.scss";

const Hero = ({ title, description }) => {
  return (
    <Section className="Hero">
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </Section>
  );
};

export default Hero;
